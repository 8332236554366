<template>
  <v-dialog v-model="isOpen" max-width="600px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <k-title>{{ $t('approvals.dialogReject.title') }}</k-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <KTextarea :label="$t('approvals.dialogReject.reason')" v-model="reason" required/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <KBtn color="secondary" outlined @click="$emit('close')">{{ $t('actions.cancel') }}</KBtn>
        <KBtn :disabled="!reason" @click="$emit('save', reason)">{{ $t('actions.continue') }}</KBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KBtn from '@/components/KButton.vue';
import KTitle from '@/components/layout/KTitle.vue';

export default {
  name: 'rejectDialog',
  components: {
    KTitle,
    KBtn,
    KTextarea,
  },
  data() {
    return {
      reason: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
